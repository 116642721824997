.setting-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;

  .setting-icon {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
    padding: 1.25rem;
    border: 1px solid #edf2f9;
    margin-right: 0.5rem;
    background: #f8fbfd;
  }
  .settion-header-left {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .setting-header-details {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    .setting-header-title {
      text-align: left;
      font-size: 1.2rem;
      color: #12263f;
      font-weight: 600;
      line-height: 1.3;
    }
    .setting-header-subtitle {
      color: #6e84a3;
    }
  }
}
