.pagewrapper {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: auto;
    background-color: #FFF;
    height: 140px;
}

.logo-section {
    margin: 0px;
    height: 99px;
    float: left;
    width: 192px;
}

.title-section {
    margin: 20px;
    height: 20px;
    float: left;
    width: 55%;
    text-align: center;
    padding-left: 25px;

    h1 {
        font-size: 28px;
        font-style: italic;
        color: #016CA4;
        margin: 0;
        clear: both;
        padding-top: 0;
        padding-right: 15px;
        padding-bottom: 10px;
        padding-left: 15px;
        font-family: Tahoma, Geneva, sans-serif;
        font-weight: bold;
    }

    span {
        font-size: 20px;
        color: #016CA4;
        font-style: italic;
    }
}

.date-section {
    margin: 10px;
    height: 30px;
    float: left;
    width: 17%;

    h3 {
        font-size: 16px;
        text-align: center;
        font-family: Tahoma, Geneva, sans-serif;
        color: #016CA4;
        font-weight: bold;
    }
}

.footer-section {
    height: 20px;

    h4 {
        font-size: 12px;
        text-align: center;
        font-family: Tahoma, Geneva, sans-serif;
        color: #016CA4;
        font-weight: bold;
    }
}

.clearfix {
    clear: both;
}