.card {
  height: 420px !important;
  width: 360px !important;
  position: relative !important;
  left: -45px; // A work around. Placing the card under the native radio button
  -webkit-appearance: none;
  appearance: none;
  border: 2px solid rgba(2,28,53,0.08);
  border-radius: 14px;
  box-shadow: 7px 7px 15px rgba(2,28,53,0.08);
  cursor: pointer;
  outline: none;

  .header-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }
  
  ul {
      padding: 0em 1em 0em 2.5em !important;
  }

  img {
      margin: 0.5em 0em 1em 0em;
  } 
}

.card-title {
  font-size: 20px !important;
  margin-top: 16px !important;
}

.checked {
  transition: border 0.5s ease;
  border: 2px solid rgba(255,0,0,0.6);
}