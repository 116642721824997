.metricsCard {
  width: calc(100% / 5 - 20px);
  padding: 1rem 0.5rem;
  max-width: 30rem;
  background: #fff;
  margin: 10px;
  margin-top: 0;
  border-radius: 8px;
  box-shadow: 0 0 8px 0 rgba(37, 117, 252, 0.2);
  display: flex;
  background: #d92550;
  color: #fff;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s;
  cursor: pointer;
  &.good {
    background: #2b8456;
  }
  &.warning {
    background: #f7b924;
  }
  &.info {
    background: #16aaff;
  }

  .info {
    flex: 1 1;

    .title {
      font-size: 0.82rem;
      font-weight: 700;
      text-transform: capitalize;
    }
    .sub_title {
      font-size: 0.8rem;
      font-weight: 700;
    }
  }
  .msg {
    font-size: 0.8rem;
    text-align: right;
    font-weight: 500;
    text-transform: capitalize;
  }
  &:hover {
    transform: scale(1.03);
  }
}
.metrics_card_wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0px -10px;

  &.more-20 {
    .metricsCard {
      width: calc(100% / 5 - 20px);
    }
  }
  &.more-5 {
  }
  &.more-100 {
    .metricsCard {
      width: calc(100% / 5 - 20px);
    }
  }
  .metricsCard {
    @media only screen and (max-width: 1024px) {
      width: calc(100% / 3 - 20px);
    }
    @media only screen and (max-width: 768px) {
      width: calc(100% / 2 - 20px);
    }
    @media only screen and (max-width: 480px) {
      width: calc(100% / 2 - 10px);
      margin: 5px;
      padding: 0.75rem;
      .msg {
        font-size: 0.8rem;
      }
      .info {
        .title {
          font-size: 0.75rem;
        }
        .sub_title {
          font-size: 0.7rem;
        }
      }
    }
  }
}

.header {
  margin-bottom: 1rem;

  .header_body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #e3ebf6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: 768px) {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    .title_report {
      text-align: left;
      font-size: 1.25rem;
      color: #12263f;
      font-weight: 600;
      line-height: 1.1;
      display: inline-block;
    }
    .subtitle_report {
      text-transform: uppercase;
      font-size: 0.7rem;
      color: #95aac9;
      margin-bottom: 0.25rem;
    }
    .user_name {
      text-align: right;
      font-size: 1.25rem;
      color: #12263f;
      font-weight: 600;
      line-height: 1.1;
      display: inline-block;
      width: 77%;
    }
    .user_name2{
    align-items: center;
    display: flex;
    margin-left: auto;
    }
    .help {
      float: right;
    }
  }
}

.group_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background: #fff;
  padding: 10px;
  margin-bottom: 8px;
  font-size: 0.9rem;
  color: #12263f;
  cursor: pointer;
  text-transform: capitalize;
  border: 1px solid #e3ebf6;
  .badge_spotlight {
    background-color: #12263f;
    padding: 10px;
    margin-right: 10px;
  }
  .left-sec {
    display: flex;
    align-items: center;
  }
  .badge {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    min-width: 24px;
    min-height: 24px;
    margin-right: 0;
    background-color: #fff;
    color: #000;
    border-radius: 22px;
    line-height: 1.3;
  }

  .left-sec {
    .fa {
      margin-right: 10px;
    }
  }
  .right-sec {
    display: flex;
    gap: 5px;
    .btn-icon-danger {
      background-color: #d82550;
      border-color: #d82550;
      color: #fff;
    }
  }
  &.warning,
  &.critical,
  &.good,
  &.info {
    padding: 0;
    border-left-width: 0;
  }
  &.info {
    border-color: #16aaff;
    .badge {
      color: #fff;
      background-color: #16aaff;
    }
  }
  &.critical {
    border-color: #d82550;
    .badge {
      color: #fff;
      background-color: #d82550;
    }
  }
  &.good {
    border-color: #2b8456;

    .badge {
      background-color: #2b8456;
      color: #fff;
    }
  }
  &.warning {
    border-color: #f7b924;

    .badge {
      color: #000;
      background-color: #f7b924;
    }
  }
}
