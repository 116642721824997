.login-page {
  display: flex;
  background-image: linear-gradient(180deg, #ffffff 25%, #7bbff3 75%) !important;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  .login-wrapper {
    max-width: 27rem;
    width: 100%;

    .app-logo {
      background: url("../../../assets/perfscan_logo_nobg.png");
      // width: 193px;
      height: 90px;
      background-repeat: no-repeat;
      background-size: contain;
      margin: auto;
      display: block;
      margin-bottom: 1.5rem;
    }

    .login-title {
      font-size: 1.4rem;
      margin-bottom: 1.5rem;
      font-weight: normal;
      div {
        opacity: 0.6;
      }
      span {
        font-size: 1.1rem;
      }
    }

    .login-box {
      padding: 1rem;
      background-color: #fff;
      box-shadow: 0 0 5px 0 rgba(37, 117, 252, 0.2);

      .form-control {
        margin-bottom: 10px;
      }
      .btn-primary {
        margin: 2rem auto 0;
        display: flex;
        align-items: center;
        i {
          margin-right: 5px;
          font-size: 0.85rem;
        }
      }
    }
  }
  .copyright-text {
    text-align: center;
    margin: 1rem 0;
    color: #fff;
  }
}
