.enterprise_edit_options_wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  .options-right,
  .options-left {
    .form-group {
      min-width: 12rem;
    }
  }
  .options-right {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    .add-options {
      position: relative;
      .custom_popup_wrapper.option-group {
        top: 2rem;
        left: unset;
        right: 0;
        z-index: 5;
        min-width: 12rem;
      }
    }
  }
}
