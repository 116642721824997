.nav-tabs {
  // border-bottom: 1px solid #e3ebf6;
  border-width: 0;
  .nav-item {
    margin-right: 0.75rem;
    a.nav-link {
      font-weight: 600;
      padding: 0.8rem;
      cursor: pointer;
      color: #12263f;
      border-width: 0px;
      border-radius: 0.25rem;
      font-size: 0.85rem;
      background-color: transparent;
      border-color: transparent;
      text-align: center;
      line-height: 1.1;

      &.active {
        color: #ffffff;
        background-color: #036ba3;
        box-shadow: 0px 2px 7px 1px #75c5f0;
      }
    }
  }
}
.notification_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e3ebf6;
  padding: 0 1rem;
  .nav-tabs {
    border-bottom-width: 0px;
  }
  .title {
    font-size: 0.95rem;
    color: #12263f;
    font-weight: 600;
  }
}
.pie-inside-tabbing {
  justify-content: center;
  margin-bottom: 25px;
}
.pie-chart-wrapper {
  position: relative;
  min-height: 10rem;
}
