.show-field {
  display: block;
  margin: 8px 0 4px;
  input {
    display: block;
    font-size: 0.8rem;
    width: 100%;
  }
}
.checkbox-wrapper {
  input.checkbox-item {
    margin-right: 5px;
    top: 2px;
    position: relative;
  }
  input.checkbox-item[disabled]{
    cursor:not-allowed;
  }
}

.detail-analysis {
  p {
    text-align: left;
    margin-bottom: 5px;
    font-weight: 700;
    font-size: 14px;
    color: #12263f;
  }
}
.chart_view_custom{
  margin-bottom: 10px;
  padding: 0.75rem;
  background-color: #fff;
  border-radius: 8px;
  border:1px solid #edf2f9;
  .detail-analysis {
    width: 100%;
  }
}

.chat_main_wc {
  background: #fff;
  margin: 10px 0;
  border-radius: 8px;
  // box-shadow: 0 0 5px 0 rgba(37, 117, 252, 0.2);
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  display: flex;
  align-items: flex-start;
  text-align: center;
  justify-content: center;
  padding: 0.75rem;
  min-height: 25rem;
  flex: 1 1 auto;
  border: 1px solid #edf2f9;
  height: 100vh;
  width: 88%;
  &.hide-chart {
    display: none;
  }
  &.no_data {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media only screen and (max-width: 1024px) {
    width: 88%;
  }
  @media only screen and (max-width: 768px) {
    width: 88%;
    overflow: auto;
  }
}

.chart_container_wc {
  width: 88%;
  display: flex;
  gap: 20px;
  height:100vh;
}