.mobile-top-nav {
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  background-color: #fff;
  border-color: #e3ebf6;
  border-width: 0 0 1px;
  border-style: solid;
  position: sticky;
  top: 0;
  z-index: 10;
  display: none;
  @media only screen and (max-width: 767px) {
    display: flex;
  }

  .btn-toggle {
    background-color: transparent;
    color: #000;
    border-width: 0px;
    outline: none;
    box-shadow: none;
    position: absolute;
    font-size: 1.1rem;
  }
  .navbar-brand-main {
    display: block;
    margin: auto;
    img {
      max-width: 100%;
      max-height: 2.5rem;
      min-height: 2.5rem;
    }
  }
}
