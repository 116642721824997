.main_header {
  display: flex;
  justify-content: space-between;
  // background: #fafbfc;
  // box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
  padding: 1rem 1.5rem;
  align-items: center;
  align-content: center;
  position: relative;
  z-index: 12;
  transition: all 0.2s;
  margin-top: auto;
  border-top: 1px solid #e3ebf6;
  .right_section {
    display: flex;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
