.existing_reports_list {
  display: flex;
  width: 100%;
  // margin: 1rem 0 1rem;
  background-color: #fff;
  padding: 0.5rem;
  border-radius: 8px;
  box-shadow: 0px 0px 2px #c9c9c9;
  align-items: flex-start;
  gap: 10px;
  flex-wrap: wrap;

  .report {
    position: relative;

    .custom_popup_wrapper {
      top: 2rem;
      left: 0;
      right: unset;
      bottom: unset;
      min-width: 23rem;
      max-width: 30rem;
      padding-top: 0;
      z-index: 5;
      overflow: auto;
      margin-top: 0.25rem;
    }
  }
}