.custom-breadcrumbs {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-bottom: 0.25rem;

  li {
    position: relative;
    &:last-child {
      margin-right: 0;
    }
    a,
    span,
    .slash {
      text-transform: uppercase;
      font-size: 0.7rem;
      color: #95aac9;
    }
    .slash {
      margin: 0px 5px;
    }
  }
}
