.build_para_card_sys {
        min-width: 30%;
        background: #fff;
        border-radius: 8px;
        position: relative;
        text-align: left;
        padding: 0.5rem;
        transition: all 0.2s;
        border: 2px solid #edf2f9;
        cursor: pointer;
        margin-top: 20px;

        .build_title {
            font-weight: bold;
        }

        .header {
            display: flex;
            flex-direction: row;
            justify-content: start;
            align-content: space-evenly;
            flex-wrap: wrap;
            width: 100%;
            .sectionA {
                width: 50%;
                display: flex;
                flex-direction: column;
            }
            .sectionB {
                width: 50%;
                align-items: center;
                display: flex;
                justify-content: center;
            }
        } 
}


.systems {
    margin-top: 20px;
}