.custom_popup_wrapper {
  position: absolute;
  bottom: 4rem;
  left: 1rem;
  right: 1rem;
  background: #fff;
  transition: all 0.3s ease;
  margin-top: 10px;
  list-style-type: none;
  padding: 0;
  min-height: 10rem;
  max-width: 25rem;
  border-radius: 8px;
  box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.18);
  display: flex;
  flex-direction: column;
  padding: 0 0 0.5rem 0;

  .popup_header {
    padding: 1.5rem 0.5rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e3ebf6;
    flex-direction: column;
    text-align: center;
    position: relative;
    background-image: url("../../assets/abstract2.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 8px 8px 0 0;

    .bg_image {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      z-index: 8;
      opacity: 0.75;
      border-radius: 8px 8px 0 0;
    }

    .popup_title {
      font-size: 1.1rem;
      font-weight: 600;
      line-height: 1.2;
      color: #fff;
      z-index: 8;
    }

    .popup_subtitle {
      font-size: 0.8rem;
      color: #fff;
      z-index: 8;
    }
  }

  .popup_body_no_data {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    text-align: center;
    -ms-transform: translateY(50%);
    transform: translateY(50%);
  }
  .popup_body {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left:0.25rem;
    
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        padding: 0.4rem 1rem;

        a {
          color: #2073e7;
          //   padding: 0.15rem 1rem;
          font-size: 0.8125rem;
          line-height: 1.5;
          user-select: none;
          cursor: pointer;
          text-decoration: none;
          transition: all 300ms;

          &:hover {
            color: #1e93d9;
          }
        }
      }
    }
  }
}