.notification_section {
  position: relative;
}

.notification_icon,
.setting_icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #6e84a3;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 300ms;
  &:hover {
    color: #12263f;
  }
}
.modal-notification {
  .notification_body {
    .notification_item {
      display: flex;
      width: 100%;
      align-items: center;
      margin-bottom: 0.75rem;
      margin-top: 0.75rem;
      padding-left: 1rem;
      padding-right: 1rem;
      .noti_icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        line-height: 0;
        background-color: #d5e5fa;
        color: rgb(44, 123, 229);
        margin-right: 1rem;
      }
      .title {
        color: #12263f;
        font-size: 0.88rem;
        font-weight: 500;
        user-select: none;
      }
      .sub_title,
      .text_muted {
        color: #6e84a3;
        font-size: 0.75rem;
        line-height: 1.1;
      }
    }
  }
}

.page_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e3ebf6;
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;
  @media only screen and (max-width: 767px) {
    align-items: flex-start;
    flex-direction: column;
  }
  .nav-tabs {
    border-bottom-width: 0px;
    .nav-item a.nav-link {
      padding: 1.5rem 0.5rem;
    }
  }
  .title_report {
    text-align: left;
    font-size: 1.25rem;
    color: #12263f;
    font-weight: 600;
    line-height: 1.1;
  }
  .subtitle_report {
    text-transform: uppercase;
    font-size: 0.7rem;
    color: #95aac9;
    margin-bottom: 0.25rem;
  }
}
